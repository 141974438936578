<template>
    <div class="stream-layout">
        <LoadingSpinner :loading="loading" />
        <Topbar :joinStreamPageShow="joinStreamPageShow" />
        <Join v-show="joinStreamPageShow" :joinStream="joinStream" />
        <Intro v-if="false" />
        <v-row v-show="!joinStreamPageShow" class="m-0 h-full" id="layout-root">
            <v-col cols="10" class="h-full relative p-0">
                <div id="layout" class="bounds"></div>
            </v-col>
            <v-col cols="2">
                <CameraList v-if="!joinStreamPageShow" :switchCamera="switchCamera" />
            </v-col>
        </v-row>
        <Footer v-if="!joinStreamPageShow" :cameraControl="cameraControl" :audioControl="audioControl" :settingModalControl="settingModalControl" :recordStart="recordStart" :recordStop="recordStop" :recordingState="recordingState" :endStream="endStream" />
        <v-dialog v-model="joinModal" persistent max-width="400" dark>
            <v-card color="#1C1D2D">
                <v-card-title class="headline"> You are invited! </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" style="text-align: center">
                            <v-card-text>Please input your name</v-card-text>
                            <v-text-field v-model="guestName" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="guestJoinStream"> OK </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="settingModal" persistent max-width="600" dark>
            <v-card color="#1C1D2D">
                <v-card-title class="headline"> Setting </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="2" sm="2" md="2">
                            <v-card-text>Audio</v-card-text>
                        </v-col>
                        <v-col cols="10" sm="10" md="10">
                            <v-select solo background-color="#2D2E40" dark item-text="label" :items="audioDevices" v-model="currentAudioDeviceSelected" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="saveSetting"> OK </v-btn>
                    <v-btn color="primary" text @click="settingModalControl(false)"> Cancel </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="completedSessionModal" persistent width="500" light>
            <v-card>
                <v-card-title class="headline"> Stream Finished </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" style="text-align: center">
                            <p>Thanks for using our service</p>
                            <p v-if="recordedVideolist.length > 0">These are recorded videos, you can watch or download</p>
                            <v-simple-table v-if="recordedVideolist.length > 0" dense style="margin-top: 20px">
                                <template #default>
                                    <tbody>
                                        <tr v-for="(record, index) in recordedVideolist" :key="index" class="video-record-list">
                                            <td style="text-align: left">
                                                <span>{{ record.name }}.mp4</span>
                                            </td>
                                            <td>
                                                <v-btn icon @click="videoPreview(record.name, record.url, index)">
                                                    <v-icon color="#1976d2"> mdi-play-circle </v-icon>
                                                </v-btn>
                                                <v-btn icon :loading="recordedVideoDownloading(index)" :disabled="recordedVideoDownloading(index)" @click="recordedVideoDownload(record.name, record.url, index)">
                                                    <v-icon color="#1976d2"> mdi-download </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text  @click="exit()"> OK </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="videoPreviewModal" persistent width="720" light>
            <v-card>
                <v-card-title class="headline">
                    {{ videoPreviewName }}
                </v-card-title>
                <v-card-text>
                    <video ref="videoPreview" :src="videoPreviewUrl" controls width="670" height="378" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="videoPreviewModalHide()"> OK </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import Topbar from "./atoms/Topbar";
import Footer from "./atoms/Footer";
import CameraList from "./atoms/CameraList";
import Join from "@/components/Join";
import LoadingSpinner from "@/components/LoadingSpinner";
import Intro from "@/components/Intro";
import AgoraRTC from "agora-rtc-sdk-ng";
import RecordRTC from "recordrtc";
import VideoLayout from "./atoms/layout";

export default {
    components: {
        Topbar,
        Footer,
        Join,
        LoadingSpinner,
        Intro,
        CameraList,
    },
    name: "LiveStream",
    data() {
        return {
            joinStreamPageShow: true,
            agoraToken: null,

            joinModal: false,
            guestName: null,

            agoraOption: {
                config: {
                    mode: "live",
                    codec: "vp8",
                },
                appId: process.env.VUE_APP_AGORA_APP_ID,
            },

            loading: true,

            agoraClient: null,
            audioTrack: null,
            videoTrack: null,

            layout: new VideoLayout(),
            hasBeenUpdated: false,

            remoteUsers: [],

            isHost: false,

            settingModal: false,

            audioDevices: [],
            currentAudioDeviceId: 0,
            currentAudioDeviceSelected: null,

            recordingState: false,
            recordingStream: null,
            recorder: null,
            recordedVideolist: [],

            videoPreviewModal: false,
            videoPreviewName: '',
            videoPreviewUrl: null,

            completedSessionModal: false,

        };
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.onbeforeunload);
        window.removeEventListener("resize", this.updateLayout);
        window.removeEventListener("resize", this.checkSize);
    },
    mounted() {
        AgoraRTC.disableLogUpload();
        AgoraRTC.setLogLevel(4);
        if (this.$route.query.invite) {
            this.joinModal = true;
            this.loading = false;
        } else {
            this.$store
                .dispatch("initLiveStream", {
                    token: this.$route.query.auto_redirect,
                    room_id: this.$route.params.id,
                })
                .then((data) => {
                    if (data) {
                        this.loading = false;
                    } else {
                        this.$router.push("/422");
                    }
                });
        }
    },
    methods: {
        joinStream() {
            this.joinStreamPageShow = false;
            this.loading = true;
            this.agoraClient = AgoraRTC.createClient(this.agoraOption.config);
            const channelName = "live-" + this.$route.params.id;
            const token = this.$store.state.agoraAccessToken;
            const UID = this.$store.state.user.id;
            this.agoraClient.setClientRole("host");
            this.agoraClient.join(this.agoraOption.appId, channelName, token, UID).then(async () => {
                const videoDevices = this.$store.state.videoDevices;
                const audioDevices = this.$store.state.audioDevices;
                if (videoDevices.length > 0) {
                    this.videoTrack = await AgoraRTC.createCameraVideoTrack({ encoderConfig: "4K", cameraId: videoDevices[this.$store.state.currentVideoDeviceId].deviceId });
                    await this.agoraClient.publish(this.videoTrack);
                    const localVideoRoot = document.createElement("div");
                    localVideoRoot.className = "stream-video OT_root OT_publisher custom-class";
                    localVideoRoot.id = "localUser";
                    const MyVideoContainer = document.createElement("div");
                    MyVideoContainer.id = UID.toString();
                    MyVideoContainer.className = "OT_widget-container";
                    const streamScreen = document.createElement("div");
                    streamScreen.className = "stream-screen";
                    const streamComponent = document.createElement("div");
                    streamComponent.className = "streamComponent";
                    const playerId = "player-" + UID.toString();
                    streamComponent.id = playerId;
                    streamScreen.append(streamComponent);
                    MyVideoContainer.append(streamScreen);
                    localVideoRoot.append(MyVideoContainer);
                    document.getElementById("layout").append(localVideoRoot);
                    this.videoTrack.play(playerId);
                }
                if (audioDevices.length > 0) {
                    this.audioDevices = audioDevices;
                    this.currentAudioDeviceId = this.$store.state.currentAudioDeviceId;
                    this.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId: audioDevices[this.$store.state.currentAudioDeviceId].deviceId });
                    await this.agoraClient.publish(this.audioTrack);
                    this.currentAudioDeviceSelected = audioDevices[this.$store.state.currentAudioDeviceId].label;
                }
                this.loading = false;
                this.updateLayout();
            });
            this.agoraClient.on("user-published", async (user, mediaType) => {
                if (this.remoteUsers.findIndex((item) => item.uid === user.uid) === -1) {
                    this.remoteUsers.push(user);
                }
                await this.agoraClient.subscribe(user, mediaType);
                if (mediaType === "video") {
                    const remoteVideoRoot = document.createElement("div");
                    remoteVideoRoot.className = "stream-video OT_root OT_publisher custom-class";
                    remoteVideoRoot.id = "remoteUsers";
                    const remoteVideoTrack = user.videoTrack;
                    const playerContainer = document.createElement("div");
                    playerContainer.id = user.uid.toString();
                    playerContainer.className = "OT_widget-container";
                    const streamScreen = document.createElement("div");
                    streamScreen.className = "stream-screen";
                    const streamComponent = document.createElement("div");
                    streamComponent.className = "streamComponent";
                    streamComponent.id = "player-" + user.uid.toString();
                    streamScreen.append(streamComponent);
                    playerContainer.append(streamScreen);
                    remoteVideoRoot.append(playerContainer);
                    document.getElementById("layout").append(remoteVideoRoot);
                    const playerId = "player-" + user.uid.toString();
                    remoteVideoTrack.play(playerId);
                }
                if (mediaType === "audio") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack.play();
                }
                this.updateLayout();
            });
            this.agoraClient.on("user-unpublished", (user, mediaType) => {
                if (mediaType === "video") {
                    this.remoteUsers.splice(
                        this.remoteUsers.findIndex((item) => item.uid === user.uid),
                        1
                    );
                    document.getElementById(user.uid).parentElement.remove();
                    this.updateLayout();
                }
            });
            const videoLayoutOptions = {
                maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
                minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
                fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
                bigClass: "OV_big", // The class to add to elements that should be sized bigger
                bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
                bigFixedRatio: false, // fixedRatio for the big ones
                bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
                bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
                bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
                animate: true, // Whether you want to animate the transitions
            };
            this.layout.initLayoutContainer(document.getElementById("layout"), videoLayoutOptions);
            window.addEventListener("beforeunload", this.onbeforeunload);
            window.addEventListener("resize", this.updateLayout);
            window.addEventListener("resize", this.checkSize);
        },
        guestJoinStream() {
            if (this.guestName && this.guestName.trim() !== "") {
                this.joinModal = false;
                this.loading = true;
                this.$store
                    .dispatch("initInviteLiveStream", {
                        token: this.$route.query.invite,
                        room_id: this.$route.params.id,
                        name: this.guestName,
                    })
                    .then((data) => {
                        if (data) {
                            this.loading = false;
                        } else {
                            this.$router.push("/422");
                        }
                    });
            }
        },
        onbeforeunload() {
            // this.leaveSession()
        },
        updateLayout() {
            var height = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
            document.getElementById("layout").style.height = height - 64 - 52 + "px";
            setTimeout(() => {
                this.layout.updateLayout();
            }, 20);
        },
        checkSize() {
            if (document.getElementById("layout") && document.getElementById("layout").offsetWidth <= 700 && !this.hasBeenUpdated) {
                this.hasBeenUpdated = true;
            }
            if (document.getElementById("layout") && document.getElementById("layout").offsetWidth > 700 && this.hasBeenUpdated) {
                this.hasBeenUpdated = false;
            }
        },
        switchCamera(deviceId) {
            this.videoTrack.setDevice(deviceId);
        },
        cameraControl(state) {
            this.videoTrack.setEnabled(state);
        },
        audioControl(state) {
            this.audioTrack.setEnabled(state);
        },
        settingModalControl(state) {
            if (state) {
                this.currentAudioDeviceSelected = this.audioDevices[this.$store.state.currentAudioDeviceId].label;
            }
            this.settingModal = state;
        },
        saveSetting() {
            this.settingModal = false;
            this.currentAudioDeviceId = this.audioDevices.findIndex((item) => item.label === this.currentAudioDeviceSelected);
            this.$store.dispatch("setCurrentAudioDeviceId", this.currentAudioDeviceId);
            this.audioTrack.setDevice(this.audioDevices[this.currentAudioDeviceId].deviceId);
        },
        async recordStart() {
            if (!this.recordingState) {
                this.recordingStream = await navigator.mediaDevices.getUserMedia({
                    audio: {deviceId: this.audioDevices[this.$store.state.currentAudioDeviceId].deviceId},
                    video: {deviceId: this.$store.state.videoDevices[this.$store.state.currentVideoDeviceId].deviceId},
                });
                this.recorder = RecordRTC(this.recordingStream, {
                    type: "video",
                    mimeType: "video/webm;codecs=vp8",
                });
                this.recorder.startRecording();
                this.recordingState = true;
            }
        },
        async recordStop() {
            if (this.recordingState) {
                const context = this;
                this.recorder.stopRecording((url) => {
                    context.recordedVideolist.push({
                        name: "record_" + new Date().getTime(),
                        url,
                        downloading: false,
                    });
                    context.recordingState = false;
                    this.recordingStream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                });
            }
        },
        endStream() {
            if (this.recordingState) this.recordStop();
            this.completedSessionModal = true;
        },
        videoPreview(name, url) {
            this.videoPreviewModal = true
            this.videoPreviewUrl = url
            this.videoPreviewName = name
            // this.$refs.videoPreview.play()
            setTimeout(() => {
                if (this.$refs.videoPreview) {
                    this.$refs.videoPreview.play()
                } else {
                    setTimeout(() => {
                        this.$refs.videoPreview.play()
                    }, 3000)
                }
            }, 2000)
        },
        videoPreviewModalHide() {
            if (this.$refs.videoPreview) {
                this.$refs.videoPreview.pause()
                this.$refs.videoPreview.currentTime = 0
                this.videoPreviewModal = false
            }
        },
        recordedVideoDownloading(_index) {
            return this.recordedVideolist[_index].downloading
        },
        recordedVideoDownload(_name, _url, _index) {
            this.recordedVideolist[_index].downloading = true
            axios.get(_url, { responseType: 'arraybuffer' }).then((res) => {
                this.recordedVideolist[_index].downloading = false
                const blob = new Blob([res.data], { type: 'application/*' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = _name + '.mp4';
                link._target = 'blank'
                link.click()
            })
        },
        exit(){
            window.location.replace('https://www.athleticevents.com');
        }
    },
};
</script>
<style>
.stream-layout {
    height: 100%;
}
.bounds {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    height: inherit;
    width: inherit;
}
.OT_root,
.OT_root * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}
.OT_publisher,
.OT_subscriber {
    position: relative;
    min-width: 48px;
    min-height: 48px;
    width: 100%;
    height: 100%;
}
.OT_publisher .OT_video-element,
.OT_subscriber .OT_video-element {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    -webkit-transform-origin: 0 0;

    transform-origin: 0 0;
}
.OT_publisher.OT_mirrored .OT_video-element {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.custom-class {
    min-height: 0px !important;
}
.OT_widget-container {
    width: inherit;
    height: inherit;
    position: relative;
    overflow: hidden;
}
.streamComponent {
    height: 100%;
}
.stream-screen {
    height: calc(100% - 10px);
    margin: 6px 3px 5px;
}
.h-full {
    height: 100%;
}
.p-0 {
    padding: 0px;
}
.m-0 {
    margin: 0px;
}
.relative {
    position: relative;
}
</style>
