<template>
    <v-app class="error-layout">
        <div class="error-text-layout">
            <p>
                {{ pageNotFound }}
            </p>
            <a href="https://www.athleticevents.com/tickets"> Back </a>
        </div>
    </v-app>
</template>

<script>
export default {
    layout: "empty",
    name: "422",
    data() {
        return {
            pageNotFound: "Token is Invalid, Please Check Again",
        };
    },
    created() {
        document.title = "Invalid Token";
    },
};
</script>

<style scoped>
.error-layout{
    background: #0A0A15;
}
.error-text-layout {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    text-align: center;
}
.error-text-layout a {
    font-size: 30pt;
    text-decoration: none;
    color: #ffbb00;
}
.error-text-layout p {
    font-size: 30pt;
    color: white;
}
</style>
